// import { configureStore, combineReducers } from '@reduxjs/toolkit'
// import LoginSlice from './slice/LoginSlice';
// import UserSlice from './slice/UserSlice';
// import CartSlice from './slice/CartSlice';

// const rootReducer = combineReducers({
//     login: LoginSlice,
//     user: UserSlice,
//     cart: CartSlice,
// });

// export const store = configureStore({
//     reducer: rootReducer,
// });


// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import LoginSlice from './slice/LoginSlice';
import UserSlice from './slice/UserSlice';
import CartSlice from './slice/CartSlice';
import { loadState, saveState } from './localStore';
import DefaultAddressSlice from './slice/DefaultAddressSlice';

const rootReducer = combineReducers({
  login: LoginSlice,
  user: UserSlice,
  cart: CartSlice,
  defaultAddress: DefaultAddressSlice,
});

const preloadedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(
  throttle(() => {
    saveState({
      login: store.getState().login,
      user: store.getState().user,
      cart: store.getState().cart,
      defaultAddress: store.getState().defaultAddress,
    });
  }, 1000)
);

export default store;

