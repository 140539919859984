import React, { useState } from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIRequest, ApiUrl } from '../../utils/api';

export const ForgotPassword = () => {
  const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [passwordNew, setNewPassword] = useState('');

    function resetFun() {
        setOtp('')
        setNewPassword('')
    }



    const forgotPasswordFun = () => {
        setIsLoading(true)
        let config = {
            url: ApiUrl?.forgotPassword,
            method: "post",
            body: {
                "data": email,
            }
        }
        APIRequest(
            config,
            res => {
                if (!res?.error) {
                    setIsLoading(false)
                    setIsEmail(true)
                    toast.success(res?.message)
                }
            },
            err => {
                setIsLoading(false)
                if (err?.error) {
                    toast.error(err?.message)
                }
            }
        )
    }

    const resetPasswordFun = () => {
        setIsLoading(true)
        let config = {
            url: ApiUrl?.resetPassword,
            method: "post",
            body: {
                "data": email,
                "OTP": otp,
                "password": passwordNew
            }
        }
        APIRequest(
            config,
            res => {
                if (!res?.error) {
                    setIsLoading(false)
                    resetFun()
                    setIsEmail(false)
                    toast.success(res?.message)
                    navigate('/sign-in')
                }
            },
            err => {
                setIsLoading(false)
                if (err?.error) {
                    toast.error(err?.message)
                }
            }
        )
    }

    const sendEmailHandler = () => {
        if (email === '') {
            alert('Email field is required')
        } else {
          forgotPasswordFun()
            // alert('click')
        }
    }

    const resetPasswordHandler = () => {
      if (otp === "") {
        alert("OTP field is required");
      } else if (passwordNew === "") {
        alert("New Password field is required");
      } else {
        // alert('Your Password is forgot Sucessfull')
        // resetFun()
        resetPasswordFun();
        navigate('/sign-in')
      }
    }
  return (
    <LayoutContainer>
      <section className="vh-150">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 ">
                  <h3 className="mb-3 text-center">
                    {isEmail ? <p>Forgot Password</p> : "Verify Email Address"}
                  </h3>
                  <div className="d-flex flex-column mb-3">
                    <label className="mb-1">Email</label>
                    <input
                      className="form-control p-2 border border-1 shadow-none"
                      type="email"
                      placeholder="Enter Email"
                      disabled={isEmail ? "disabled" : ""}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  {isEmail && (
                    <>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Enter Valid OTP</label>
                        <input
                          className="form-control p-2 border border-1 shadow-none"
                          type="password"
                          placeholder="OTP"
                          onChange={(e) => setOtp(e.target.value)}
                          value={otp}
                        />
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">New Password</label>
                        <input
                          className="form-control p-2 border border-1 shadow-none"
                          type="password"
                          placeholder="New Password"
                          onChange={(e) => setNewPassword(e.target.value)}
                          value={passwordNew}
                        />
                      </div>
                    </>
                  )}
                  <div>
                    {!isEmail ? (
                      <button
                        type="button"
                        onClick={sendEmailHandler}
                        className="btn btn-danger btn-lg btn-block fs-6 text-transform-none w-100 shadow-none"
                      >
                        {isLoading ? <>Processing...</> : "Send Email"}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={resetPasswordHandler}
                        className="btn btn-danger btn-lg btn-block fs-6 text-transform-none w-100 shadow-none"
                      >
                        {isLoading ? <>Processing...</> : "Continue"}
                      </button>
                    )}
                  </div>
                  {/* <button
                    className="btn btn-danger btn-lg btn-block fs-6 text-transform-none w-100"
                    type="submit"
                  >
                    Reset Password
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LayoutContainer>
  );
};
