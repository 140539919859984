import React, { useEffect, useState } from "react";
import { OrderProductDetail } from "../OrderProductDetail/OrderProductDetail";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";

export const MyOrderDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const MyOrder = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.myOrder}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        setOrders(res?.data || []);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  useEffect(() => {
    MyOrder();
  }, []);

  return (
    <>
      <section className="gradient-custom">
        <div className="container py-5">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-10 col-xl-8">
              <div className="card" style={{ borderRadius: "10px" }}>
                <div className="card-header px-4 py-4">
                  <h5 className="mb-0">Order History</h5>
                </div>
                <div className="card-body p-4">
                  {isLoading ? (
                    <Loader isLoading={isLoading} />
                  ) : (
                    orders.map((order) => (
                      <div
                        key={order._id}
                        className="card shadow-0 border mb-4"
                      >
                        <div className="card-body">
                          <h6>Order #{order.invoiceId}</h6>
                          {order?.productDetails?.map((productDetail) => (
                            <div
                              key={productDetail?.product?.productId}
                              className="row d-flex justify-content-between align-items-center"
                              // onClick={() => handleShow(order)}
                            >
                              <div className="col-md-2">
                                <img
                                  src={productDetail?.details?.images[0].url}
                                  className="img-fluid"
                                  alt={
                                    productDetail?.details?.name || "Product"
                                  }
                                  // style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                                <p className="mb-0">
                                  {productDetail?.details?.name}
                                </p>
                              </div>
                              <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                                <p className="mb-0 small">
                                  Qty: {productDetail?.product?.quantity}
                                </p>
                              </div>
                              <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                                <p className="mb-0 small">
                                  ${productDetail?.product?.total}
                                </p>
                              </div>
                            </div>
                          ))}
                          {/* <hr className="mb-4" style={{ backgroundColor: "#e0e0e0", opacity: "1" }} />
                          <div className="row d-flex align-items-center">
                            <div className="col-md-2">
                              <p className="mb-0 small">Track Order</p>
                            </div>
                            <div className="col-md-10">
                              <div className="progress" style={{ height: "6px", borderRadius: "16px" }}>
                                <div className="progress-bar" role="progressbar"
                                  style={{ width: "65%", borderRadius: "16px", backgroundColor: "#a8729a" }} aria-valuenow="65"
                                  aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <div className="d-flex justify-content-around mb-1">
                                <p className="mt-1 mb-0 small ms-xl-5">Out for delivery</p>
                                <p className="mt-1 mb-0 small ms-xl-5">Delivered</p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    ))
                  )}
                  <OrderProductDetail show={show} handleClose={handleClose} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
