import React from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer'
import { WishlistDetail } from '../../Components/WishlistDetail/WishlistDetail'

export const Wishlist = () => {
  return (
    <>
      <LayoutContainer>
        <WishlistDetail />
      </LayoutContainer>
    </>
  );
}
