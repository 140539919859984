import React from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { Link } from "react-router-dom";

export const OrderSuccessfull = () => {
  return (
    <>
      <LayoutContainer>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="message-box _success">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                <h2> Your Order is Successfully Placed </h2>
                <p>
                  Thank you for your Order. we will <br />
                  be in contact with more details shortly.
                </p>
                <Link to="/">
                  <button
                    className="btn btn-danger btn-lg btn-block fs-6 text-transform-none shadow-none mt-4"
                    type="submit"
                  >
                    Go to Home
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};
