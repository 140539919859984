import React from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdClose } from "react-icons/md";

export const OrderProductDetail = ({ show, handleClose }) => {
  return (
    <section className="">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100 text-center">
          <div className="col">
            {/* <Button variant="light" size="lg" onClick={handleShow}>
              <i className="fas fa-info me-2"></i> Get information
            </Button> */}

            <Modal show={show} onHide={handleClose}>
              <Modal.Header className="d-flex justify-content-between">
                <h3>Outlet #284</h3>
                <div>
                  <MdClose
                    className="fs-2"
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Date</p>
                  <p className="small mb-0">10/May/2024 at 08:24pm</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Order Number</p>
                  <p className="small mb-0">#28282828</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Mode of Payment</p>
                  <p className="small mb-0">Paid: Paypal</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Mobile Number</p>
                  <p className="small mb-0">1234567890</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Delivered To</p>
                  <p className="small mb-0">247, Park Street,NY,110048</p>
                </div>
                <hr
                  className="mt-2 mb-2"
                  style={{
                    height: "0",
                    backgroundColor: "transparent",
                    opacity: ".75",
                    borderTop: "2px dashed #9e9e9e",
                  }}
                />
                <div className="d-flex justify-content-start">
                  <p className="fw-bolder mb-0">Bill Details</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Shipping fee</p>
                  <p className="small mb-0">$200.00</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Discount</p>
                  <p className="small mb-0">$2125.00</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="small mb-0">Sales Tax</p>
                  <p className="small mb-0">$2125.00</p>
                </div>
                <hr
                  className="mt-2 mb-2"
                  style={{
                    height: "0",
                    backgroundColor: "transparent",
                    opacity: ".75",
                    borderTop: "2px dashed #9e9e9e",
                  }}
                />
                <div className="d-flex justify-content-between">
                  <p className="small mb-0 fw-bold">SubTotal</p>
                  <p className="small mb-0">$175.00</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="
                shadow-none"
                  variant="primary"
                  size="lg"
                  onClick={handleClose}
                  style={{ backgroundColor: "#35558a" }}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};
