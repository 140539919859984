import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   userData: null,
   storeData: null
}

const UserSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      setUserData: (state, action) => {
         state.userData = action.payload;
      },
      setStoreData: (state, action) => {
         state.storeData = action.payload;
      },
      setWalletBalance: (state, action) => {
         if (state.userData) {
            state.userData.balance = action.payload;
         }
      }
   }
})


export const { setUserData, setStoreData,setWalletBalance } = UserSlice.actions;
export default UserSlice.reducer;
