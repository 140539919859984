import React from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer'
import { CartDetail } from '../../Components/CartDetail/CartDetail'

export const Cart = () => {
  return (
      <LayoutContainer>
        <CartDetail/>  
      </LayoutContainer>
  )
}
