import React, { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import { Loader } from "../../Components/Loader/Loader";

export const ProductType = () => {
  const navigation = useNavigate();
  const [CategoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");

  const GetCategory = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getCategory}`,
      method: "post",
      body: {
        page: 1,
        limit: 4,
      },
    };
    APIRequest(
      config,
      (res) => {
        // console.log(res);
        setIsLoading(false);

        setCategoryList(res?.data);
      },
      (err) => {
        // console.log(err.message, "---err");
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  useEffect(() => {
    GetCategory();
  }, []);

  return (
    <>
      <div className="container my-5 product_type">
        <div className="fs-4 fw-bold text-grey mb-2">
          What are you craving for ?
        </div>
        <div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 row-cols-xs-1 g-5 product_type_container">
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <>
              {CategoryList?.map((item) => (
                <div className="col " key={item?._id}>
                  <div className="card">
                    <Link
                      to={
                        token
                          ? `/product-type/${item?._id}?name=${item?.name}`
                          : `/sign-in`
                      }
                      // to="/product-type"
                      className="text-decoration-none"
                    >
                      <img
                        src={item?.image}
                        className="card-img-top"
                        alt="..."
                        style={{ height: "25vh" }}
                      />
                    </Link>
                    <div className="card-body">
                      <h5 className="card-title fw-bold d-flex justify-content-center">
                        {item?.name || "Category Name"}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {/* <Loader isLoading={isLoading} /> */}
      </div>
    </>
  );
};
