import React, { useState } from 'react'
import { APIRequest, ApiUrl } from '../../utils/api';
import { toast } from 'react-toastify';

export const FeedbackDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackActive, setFeedbackActive] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [componentId, setComponentId] = useState(1);

  const uploadFeedback = () => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.feedbackAdd,
      method: "post",
      body: {
        rating: feedbackActive,
        description: feedbackMessage
      }
    };
    // console.log("Feedback", config);
    APIRequest(
      config,
      res => {
        if (!res?.error) {
          setIsLoading(false);
          toast.success(res?.message);
          setComponentId(2);
          resetFun();
        }
      },
      err => {
        setIsLoading(false);
        if (err?.error) {
          toast.error(err?.message);
        }
      }
    );
  };

  const resetFun = () => {
    setFeedbackActive(null);
    setFeedbackMessage('');
  };

  return (
    <>
      <section>
        <div className="container mb-5 d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-8 col-lg-6 col-xl-6">
            <h3 className="text-center text-black fw-bold mt-5 mb-4">
              Rate Us
            </h3>
            <p className="text-center text-black mb-3">
              How happy are you?
            </p>
            <div className="p-3 shadow-lg rounded w-100 d-flex align-items-center justify-content-between gap-2">
              {feedbackArray.map((feedback, index) => (
                <div
                  onClick={() => setFeedbackActive(feedback.id)}
                  key={index}
                  className={`p-1 cursor-pointer border w-100 text-center rounded fw-bold ${feedbackActive === feedback.id ? 'activeColor1' : ''}`}
                  style={{ cursor: "pointer" }}
                >
                  {feedback.feedbackNo}
                </div>
              ))}
            </div>
            <div className="mt-5 mb-3">
              <textarea
                className="form-control border rounded p-3 w-100 shadow-none"
                name="feedback"
                value={feedbackMessage}
                id="feedback"
                cols="30"
                rows="4"
                placeholder="Write Feedback (optional)"
                onChange={(e) => setFeedbackMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-danger w-100 text-lg fw-bold rounded-full shadow-none"
                style={{ transition: "all 0.5s" }}
                disabled={isLoading}
                onClick={uploadFeedback}
              >
                {isLoading ? (
                  <span>Processing...</span>
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const feedbackArray = [
  { id: 1, feedbackNo: 1 },
  { id: 2, feedbackNo: 2 },
  { id: 3, feedbackNo: 3 },
  { id: 4, feedbackNo: 4 },
  { id: 5, feedbackNo: 5 },
  { id: 6, feedbackNo: 6 },
  { id: 7, feedbackNo: 7 },
  { id: 8, feedbackNo: 8 },
  { id: 9, feedbackNo: 9 },
  { id: 10, feedbackNo: 10 }
];
