import React from "react";
import { Navbar } from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";
import { GetUpdate } from "../GetUpdate/GetUpdate";

export const LayoutContainer = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="main">{children}</div>
      {/* <GetUpdate /> */}
      <Footer />
    </>
  );
};
