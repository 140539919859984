import React from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer'
import { MyOrderDetails } from '../../Components/MyOrderDetails/MyOrderDetails'

export const MyOrder = () => {
  return (
    <LayoutContainer>
      <MyOrderDetails />
    </LayoutContainer>
  );
};
