import React from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { RewardDetails } from "../../Components/RewardDetails/RewardDetails";

const Reward = () => {
  return (
    <>
      <LayoutContainer>
        <RewardDetails />
      </LayoutContainer>
    </>
  );
};

export default Reward;
