import React, { useEffect, useState } from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import { setCart } from "../../app/slice/CartSlice";
import { useDispatch } from "react-redux";

export const PaymentSuccessfull = () => {
  const paypalData = JSON.parse(localStorage.getItem("PaypalData"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success_Payment_data, setSucess_Payment_Data] = useState({});
  const [indexUpdate, setIndexUpdate] = useState(1);
  const [IsLoading, setIsLoading] = useState(true);
  const [order_done, setOrder_Done] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const paymentId = searchParams.get("paymentId");
  const token = searchParams.get("token");
  const payerId = searchParams.get("PayerID");

  console.log("paypalDatapaypalData", searchParams);
  console.log("paypalDatapaypalData", paypalData);

  const Payment_State = () => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.paymentSucess,
      method: "post",
      body: {
        paymentId: paymentId,
        PayerID: payerId,
        token: token,
      },
    };
    APIRequest(
      config,
      (res) => {
        if (!res?.error) {
          console.log("res Payment_State ============== dd", res);
          setSucess_Payment_Data(res?.data);
          setIsLoading(false);
          setIndexUpdate(2);
        }
      },
      (err) => {
        console.log("err", err);
        if (err?.error) {
          toast.error(err?.message);
        }
        setIsLoading(false);
      }
    );
  };

  const Order_Placed = () => {
    setIsLoading(true);
    console.log("paypalDatapaypalDatapaypalData", paypalData);
    let config = {
      url: `${ApiUrl.placeOrder}`,
      method: "post",
      body: {
        cartId: paypalData?.cartId,
        addressId: paypalData?.addresId,
        storeId: paypalData?.storeId,
        // "couponId":"669a3be224348e762d622311",
        deliveryCharge: 5,
        deliveryTime: paypalData?.deleveryTime, // instant  // it will be the String
        transactionId: success_Payment_data?._id,
        isWallet: false, // false , true
        tip: paypalData?.tip, // it will only number
      },
    };
    console.log("Wallet", config);
    APIRequest(
      config,
      (res) => {
        toast.success(res?.message);
        navigate("/order-successfull");
        // Clear the cart after a successful order placement
        dispatch(setCart([]));
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  useEffect(() => {
    Payment_State();
  }, [paymentId, token, payerId]);

  useEffect(() => {
    if (success_Payment_data?._id) {
      Order_Placed();
    }
  }, [success_Payment_data?._id]);

  return (
    <>
      <LayoutContainer>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="message-box _success">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                <h2> Your payment was successful </h2>
                <p>
                  Thank you for your payment. we will <br />
                  be in contact with more details shortly{" "}
                </p>
                {/* <Link to="/">
                  <button
                    className="btn btn-danger btn-lg btn-block fs-6 text-transform-none shadow-none mt-4"
                    type="submit"
                  >
                    Go to Home
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};
