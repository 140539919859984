import React, { useState } from 'react';
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export const WishlistDetail = () => {
    const [quantity, setQuantity] = useState(1);

    const handleDecrease = () => {
      if (quantity > 0) {
        setQuantity((prevQuantity) => prevQuantity - 1);
      }
    };
  
    const handleIncrease = () => {
      setQuantity(prevQuantity => prevQuantity + 1);
    };
  return (
    <>
      <section className="h-100">
        <div className="container h-100 py-5">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-10">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="fw-bold mb-0">Wishlist</h3>
                <div>
                </div>
              </div>
              <div className="card rounded-3 mb-4">
                <div className="card-body p-4">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-md-2 col-lg-2 col-xl-2">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-shopping-carts/img1.webp"
                        className="img-fluid rounded-3"
                        alt="Cotton T-shirt"
                      />
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3">
                      <p className="lead fw-normal mb-2">Basic T-shirt</p>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-2 d-flex">
                    <button
                      className="btn btn-link px-2 shadow-none"
                      onClick={() => handleDecrease()}
                    >
                      <FaMinus style={{ color: "red" }} />
                    </button>

                    <input
                      id="form1"
                      min="0"
                      name="quantity"
                      value={quantity}
                      type="number"
                      className="form-control form-control-sm border-0"
                      readOnly
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                    />

                    <button
                      className="btn btn-link px-2 shadow-none"
                      onClick={() => handleIncrease()}
                      >
                      <FaPlus style={{ color: "red" }} />
                    </button>
                    </div>
                    <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                      <h5 className="mb-0">$499.00</h5>
                    </div>
                    <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                      <a href="#!" className="text-danger">
                      <MdDelete className="fs-3" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
