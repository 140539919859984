import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const GooglePlacesInput = ({ inputClass, address, setAddress, setValue = () => { }, }) => {
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null, fullAddress: '' });

  const handleSelect = async (address) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({
        latitude: lat,
        longitude: lng,
        fullAddress: results[0]?.formatted_address
      });
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    setValue(coordinates);
  }, [coordinates, setValue]);

  useEffect(() => {
    if (!address) {
      setCoordinates({ latitude: null, longitude: null, fullAddress: '' });
    }
  }, [address]);

  return (
    <div style={{ marginTop: 0 }}>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={{ types: ['address'] }} // Modify this to include more specific location types
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              style={{ ...inputClass, width: '100%', boxSizing: 'border-box', padding: '0 10px' }}
              {...getInputProps({
                placeholder: 'Search Places ...',
              })}
            />
            {loading && <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>Loading...</div>}
            <div style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', width: '100%', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                  padding: '10px',
                  cursor: 'pointer',
                };
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default GooglePlacesInput;
