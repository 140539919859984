import React, { useEffect, useState } from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { APIRequest, ApiUrl } from "../../utils/api";
import CountryCodeDropdown from "../../Components/CountryCodeDropdown/CountryCodeDropdown";
import axios from "axios";

export const SignUp = () => {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [otp, setOtp] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // Available languages list
  const availableLanguages = [
    "Hindi",
    "English",
    "Spanish",
    "French",
    "German",
  ];

  // State to keep track of selected languages
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  // Toggle language selection
  const handleLanguageToggle = (language) => {
    if (selectedLanguages.includes(language)) {
      // Remove the language if it's already selected
      setSelectedLanguages(
        selectedLanguages.filter((selected) => selected !== language)
      );
    } else {
      // Add the language if it's not already selected
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  const [error, setError] = useState(null);
  const [contact, setContact] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  // const [selectedCuntryCode, setselectedCuntryCode] = useState("+1");

  function resetFun() {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setFullAddress("");
    setContact("+1");
    setCountryCode("");
  }

  const registrationAuth = () => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.signup,
      method: "post",
      body: {
        data: email,
        firstName: firstName,
        lastName: lastName,
        contact: contact,
        street1: street1 || "", // Default to empty string if undefined
        street2: street2 || "",
        city: city || "",
        state: state || "",
        country: country || "",
        fullAddress: fullAddress || "",
        postalCode: postalCode || "",
        countryCode: countryCode || "",
        languages: selectedLanguages,
        password: password,
        longitude: "" + location?.lng,
        latitude: "" + location?.lat,
      },
    };
    console.log("successsuccess", config);
    APIRequest(
      config,
      (res) => {
        console.log("res", res);
        if (!res?.error) {
          setIsLoading(false);
          setIsEmail(true);
          toast.success(res?.message);
        }
      },
      (err) => {
        setIsLoading(false);
        if (err?.error) {
          toast.error(err?.message);
        }
      }
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (firstName === "") {
      toast.error("FirstName field is required");
    } else if (lastName === "") {
      toast.error("lastName field is required");
    } else if (email === "") {
      toast.error("Email field is required");
    } else if (password === "") {
      toast.error("Password field is required");
    } else if (contact === "") {
      toast.error("contact field is required");
    } else if (contact.length != 10) {
      toast.error("contact number must be 10 digit");
    } else {
      registrationAuth();
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
          console.log("Latitude:", latitude, "Longitude:", longitude); // Debugging
          setLoading(false);
        },
        (error) => {
          setError(error.message);
          setLoading(false);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const apiKey = `AIzaSyDwwL3sIm-b4s_qTweJ5u2nHSjmO7Y7RH8`;

  const fetchAddress = async (latitude, longitude) => {
    setIsLoading(true);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    try {
      const response = await axios.get(url);
      if (response.data.status === "OK") {
        const result = response.data.results[0];
        const addressComponents = result.address_components;
        console.log("addressComponents", addressComponents);

        let street1 = "";
        let street2 = "";
        let city = "";
        let state = "";
        let country = "";
        let postalCode = "";

        // Extract street1 and street2
        addressComponents.forEach((component) => {
          console.log(component);
          if (component.types.includes("route")) {
            street1 = component.long_name;
          }
          if (
            component.types.includes("sublocality") ||
            component.types.includes("neighborhood")
          ) {
            street2 = component.long_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            postalCode = component.long_name;
          }
        });

        setFullAddress(result.formatted_address);
        setCity(city);
        setState(state);
        setCountry(country);
        setStreet1(street1);
        setStreet2(street2);
        setPostalCode(postalCode);

        setIsLoading(false);
      } else {
        setError(`Geocoding Error: ${response.data.status}`);
        setIsLoading(false);
      }
    } catch (error) {
      setError(`Geocoding Request Failed: ${error.message}`);
      setIsLoading(false);
    }
  };

  const accountVerificationAuth = () => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.accountVerification,
      method: "post",
      body: {
        data: email,
        OTP: otp,
      },
    };
    APIRequest(
      config,
      (res) => {
        if (!res?.error) {
          setIsLoading(false);
          setIsEmail(false);
          resetFun();
          localStorage.setItem("token", res?.token);
          toast.success(res?.message);
          navigation("/");
        }
      },
      (err) => {
        setIsLoading(false);
        if (err?.error) {
          toast.error(err?.message);
        }
      }
    );
  };

  const verificationHandler = (e) => {
    e.preventDefault();
    if (otp === "") {
      alert("Otp field is required");
    } else {
      accountVerificationAuth();
    }
  };

  useEffect(() => {
    if (!location.lat && !location.lng) {
      getLocation();
    } else if (location.lat && location.lng) {
      fetchAddress(location.lat, location.lng);
    }
  }, [location]);

  return (
    <LayoutContainer>
      <section className="vh-150">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 ">
                  {!isEmail ? (
                    <>
                      <h3 className="mb-3 text-center">Sign Up</h3>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">First Name</label>
                        <input
                          type="name"
                          id="typeName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className="form-control p-2 border border-1 shadow-none"
                          placeholder="Enter Fist Name"
                        />
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Last Name</label>
                        <input
                          type="name"
                          id="typeName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          className="form-control p-2 border border-1 shadow-none"
                          placeholder="Enter Last Name"
                        />
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Email</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          id="typeEmail"
                          className="form-control p-2 border border-1 shadow-none"
                          placeholder="Enter Email"
                        />
                      </div>

                      {/* <div className="d-flex flex-column mb-3">
                        <label htmlFor="lable" className='className="mb-1"'>
                          Contact
                        </label>
                        <div className="flex">
                          <div className="w w-32">
                            <CountryCodeDropdown
                              setCountryCode={setCountryCode}
                              countryCode={countryCode}
                            />
                          </div>
                          <div className="max-sm:w-full">
                            <input
                              type="number"
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                              id="typContact"
                              className="form-control p-2 border border-1 shadow-none"
                              placeholder="Enter contact"
                            />
                          </div>
                        </div>
                      </div> */}

                      {/* <div>
                        <label
                          htmlFor="lable"
                          className="text-back100 block mb-2 w-full text-sm font-poppins"
                        >
                          Contact
                        </label>
                        <div style={{display:"flex"}}>
                          <div style={{width:"30%"}}>
                            <CountryCodeDropdown
                              setCountryCode={setCountryCode}
                              countryCode={selectedCuntryCode}
                              setItems={setselectedCuntryCode}
                              items={selectedCuntryCode}

                            />
                          </div>
                          <div className="max-sm:w-full">
                            <input
                              type="number"
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                              id="typContact"
                              // className="form-control p-2 border border-1 shadow-none"
                              placeholder="Enter contact"
                              className="outline-none max-sm:w-full rounded-tr-lg rounded-br-lg block mb-2 w-full bg-transparentDark px-3 py-2 placeholder:text-back500 text-back500"
                            />
                          </div>
                        </div>
                      </div> */}

                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Contact</label>
                        <input
                          type="number"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          id="typeContact"
                          className="form-control p-2 border border-1 shadow-none"
                          placeholder="Enter Contact"
                        />
                      </div>

                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          id="typePassword"
                          className="form-control p-2 border border-1 shadow-none"
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Full Address</label>
                        <input
                          type="text"
                          value={fullAddress || ""}
                          onChange={(e) => setFullAddress(e.target.value)}
                          id="fullAddress"
                          className="form-control p-2 border border-1 shadow-none"
                          placeholder="Enter Full Address"
                        />
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Language</label>
                        <div className="d-flex flex-wrap gap-3">
                          {availableLanguages.map((language) => (
                            <button
                              className={`btn fw-bold cursor-pointer rounded-pill px-3 py-1 shadow-none ${
                                selectedLanguages.includes(language)
                                  ? "btn-danger"
                                  : "btn-outline-danger"
                              }`}
                              key={language}
                              onClick={() => handleLanguageToggle(language)}
                            >
                              {language}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="form-check d-flex justify-content-start mb-4">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          value=""
                          id="registerCheck"
                          aria-describedby="registerCheckHelpText"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="registerCheck"
                        >
                          I have read and agree to the terms
                        </label>
                      </div>
                      <button
                        className="btn btn-danger btn-lg btn-block fs-6 text-transform-none w-100 shadow-none"
                        type="submit"
                        onClick={submitHandler}
                      >
                        {isLoading ? (
                          <span>Processing...</span>
                        ) : (
                          <span>Sign Up</span>
                        )}
                      </button>
                      <div className="text-center mt-3">
                        <p>
                          Already have an account?{" "}
                          <Link to="/sign-in" className="text-decoration-none">
                            SignIn
                          </Link>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <h3 className="mb-3 text-center">Account Verification</h3>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Email</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          id="typeEmail"
                          className="form-control p-2 border border-1 shadow-none"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="d-flex flex-column mb-3">
                        <label className="mb-1">Enter Valid OTP</label>
                        <input
                          className="form-control p-2 border border-1 shadow-none"
                          type="password"
                          placeholder="OTP"
                          onChange={(e) => setOtp(e.target.value)}
                          value={otp}
                        />
                      </div>
                      <button
                        className="btn btn-danger btn-lg btn-block fs-6 text-transform-none w-100 shadow-none"
                        type="submit"
                        onClick={verificationHandler}
                      >
                        {isLoading ? (
                          <span>Processing...</span>
                        ) : (
                          <span>Submit</span>
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LayoutContainer>
  );
};
