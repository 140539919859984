import React, { useEffect, useState } from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { BiWalletAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import { APIRequest, ApiUrl } from "../../utils/api";
import { Loader } from "../../Components/Loader/Loader";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "370px",
    height: "50vh",
    marginRight: "0",
    zIndex: "1100",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    border: "none",
  },
};

export const Wallet = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletOrders, setWalletOrders] = useState([]);
  const [walletBalance, setWalletBalance] = useState(null);
  const [inputwalletBalance, setInputWalletBalance] = useState(0);
  const [balance, setBalance] = useState(0);

  const [modalIsOpen, setmodalIsOpen] = React.useState(false);

  function openModal() {
    setmodalIsOpen(true);
  }

  function closeModal() {
    setmodalIsOpen(false);
  }

  const getwalletTransaction = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getwalletTransaction}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        // toast.success(res?.message);
        setIsLoading(false);

        // console.log("wallet transactions ", res?.data);
        // console.log("wallet transactions products", res?.data[0]?.productDetails);
        setWalletOrders(res?.data);
        setWalletBalance(res?.user?.wallet);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const CreateWalletTransaction = () => {
    // Validate that inputwalletBalance is a valid number
    const amount = parseFloat(inputwalletBalance);
    if (isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    console.log("input vlaue", inputwalletBalance);
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.walletTransaction_create}`,
      method: "post",
      body: {
        amount: amount,
      },
    };

    console.log("inputwalletBalance", config);
    APIRequest(
      config,
      (res) => {
        // toast.success(res?.message);
        setIsLoading(false);

        console.log("Create wallet transactions ", res?.data);
        // console.log("wallet transactions products", res?.data[0]?.productDetails);
        // setWalletOrders(res?.data);
        // setInputWalletBalance("");
        setWalletBalance(res.user?.wallet || 0);
        closeModal();
        window.location.href = res?.data?.links[1].href;
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const getUserProfile = () => {

    setIsLoading(true)
    let config = {
      url: ApiUrl?.userDetails,
      method: "get",
    }
    APIRequest(
      config,
      (res) => {
        if (!res?.error) {
          setIsLoading(false)
          setBalance(res?.user?.wallet)
        }
      },
      err => {
        setIsLoading(false)
        if (err?.error) {
          // console.log("=====Error=======",err?.message);
        }
      }
    )
  }

  useEffect(() => {
    getwalletTransaction();
    getUserProfile();
  }, []);


  console.log("jaskhjkdhkjshdfkhdsfhd", walletOrders);

  return (
    <>
      <LayoutContainer>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="message-box _success mt-0">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span>
                      <BiWalletAlt className="fs-3" />
                    </span>
                    <h4 className="ms-2">Wallet</h4>
                  </div>

                  <div>
                    <span className="fs-5 fw-bold"> ${balance.toFixed(2) || 0}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  class="btn btn-danger fw-bold cursor-pointer rounded-pill px-4 py-2 shadow-none"
                  onClick={openModal}
                >
                  Top Up
                </button>
                <Modal
                  isOpen={modalIsOpen}
                  // onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="d-flex justify-content-end">
                    <MdClose
                      className="fs-3"
                      onClick={() => setmodalIsOpen(false)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="fs-5 fw-bold">Top Up Wallet</div>
                  <div className="modal_name mt-4">
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      className="search"
                      value={inputwalletBalance}
                      onChange={(e) => setInputWalletBalance(e.target.value)}
                      min="0"
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="btn btn-danger f-6 py-1 px-3 border-none outline-none rounded-pill me-2 shadow-none"
                      onClick={CreateWalletTransaction}
                    >
                      Pay with Paypal
                    </button>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="card-header py-3">
              <h3 className="mb-0 fs-bold">Shopping Cart</h3>
            </div>
            <div className="card mb-4">
              <div className="card-header py-3">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-md-2 col-lg-2 col-xl-2">
                    <h5 className="fw-bold">Order</h5>
                  </div>
                  <div className="col-md-2 col-lg-2 col-xl-2">
                    <h5 className="fw-bold">Name</h5>
                  </div>
                  <div className="col-md-2 col-lg-2 col-xl-2">
                    <h5 className="fw-bold">Quantity</h5>
                  </div>
                  <div className="col-md-2 col-lg-2 col-xl-2">
                    <h5 className="fw-bold">Rewards</h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {isLoading ? (
                  <Loader isLoading={isLoading} />
                ) : walletOrders.length > 0 ? (
                  walletOrders?.map((order, index) => (
                    <div key={index}>
                      {order?.productDetails?.map((product, id) => (
                        <div
                          key={id}
                          className="row d-flex justify-content-between align-items-center mb-3 pb-3"
                          style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
                        >
                          <div className="col-md-2 col-lg-2 col-xl-2">
                            <img
                              src={product?.details?.images[0]}
                              className="img-fluid rounded-3"
                              alt={product?.details?.title}
                            />
                          </div>
                          <div className="col-md-2 col-lg-2 col-xl-2">
                            <h5 className="mb-0 fw-bold">
                              {product?.details?.name}
                            </h5>
                          </div>
                          <div className="col-md-2 col-lg-2 col-xl-2">
                            <h5 className="mb-0 fw-bold">
                              {product.product.quantity}
                            </h5>
                          </div>
                          <div className="col-md-2 col-lg-2 col-xl-2">
                            <h5 className="mb-0 fw-bold">
                              ${product.product.price}
                            </h5>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <p>No transactions available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};
