import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Routes/Layout";
// import 'flag-icon-css/css/flag-icon.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const App = () => {

  return (
    <>
      <ToastContainer />
      <Layout />
    </>
  );
}

export default App;



