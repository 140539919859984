import React, { useEffect, useState } from "react";
import { FaMinus, FaPlay } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../app/slice/CartSlice";
import { setStoreData } from "../../app/slice/UserSlice"; // Ensure this is correctly imported
import { Loader } from "../Loader/Loader";

export const SingleProductDetail = () => {
  const token = localStorage.getItem("token");
  // const storedStoreData = localStorage.getItem("storeData");

  const { categoryId, productId } = useParams();
  const StoreData = useSelector((state) => state.user.storeData);
  const cart = useSelector((state) => state.cart.cartData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [proDetail, setproDetail] = useState("");
  const [relproDetail, setrelproDetail] = useState([]);
  const [imageChange, setImageChange] = useState(null); // for changing main image

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // console.log("StoreData",StoreData);

  const GetProductDetails = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getProductDetails}`,
      method: "post",
      body: {
        productId: productId,
        categoryId: categoryId,
        storeId: StoreData?._id,
      },
    };
    APIRequest(
      config,
      (res) => {
        console.log("hghj", res?.data);
        setproDetail(res?.data);
        // setImageChange(res?.data?.images?.[0]?.url); // Set initial main image
        setrelproDetail(res?.relatedProducts);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const GetCart = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getCart}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        // console.log("i ama in cart details", res?.data);
        dispatch(setCart(res?.data[0]));
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const AddToCart = (productId, storeId, quantity = 1) => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.addCart,
      method: "post",
      body: {
        productId: productId,
        storeId: storeId,
        quantity: quantity,
      },
    };
    APIRequest(
      config,
      (res) => {
        setIsLoading(false);
        GetCart();
        toast.success("Product added to cart");
        navigate("/cart");

        // console.log("API Response:", res);
        // if (res?.data) {
        //   console.log("Product Added", res.data[0]?.products);
        //   dispatch(setCart(res.data[0]?.products));
        //   toast.success("Product added to cart");
        // } else {
        //   toast.error('Failed to add product to cart');
        // }
      },
      (err) => {
        setIsLoading(false);
        // console.error("API Error:", err);
        toast.error(err?.message || "Failed to add product to cart");
      }
    );
  };

  const UpdateCart = (productId, storeId, quantity) => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.addCart}`,
      method: "post",
      body: {
        productId: productId,
        storeId: storeId,
        quantity: quantity,
      },
    };
    APIRequest(
      config,
      (res) => {
        setIsLoading(false);
        toast.success(res?.message);
        dispatch(setCart(res?.created));
        GetCart();
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  console.log("imageChangeimageChange", imageChange);

  useEffect(() => {
    // Retrieve store data from local storage
    // const storedStoreData = localStorage.getItem("storeData");
    // if (storedStoreData) {
    //   dispatch(setStoreData(JSON.parse(storedStoreData)));
    // }
    GetProductDetails();
  }, [categoryId, productId]);

  const handleContinue = () => {
    token
      ? AddToCart(proDetail._id, StoreData._id, quantity)
      : navigate("/sign-in");
  };

  // console.log("@@@@@@@@",proDetail);
  // console.log("categoryId",categoryId);
  // console.log("productId",productId);
  // console.log("related products",relproDetail);

  return (
    <>
      <section>
        <div className="container my-5 ">
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <>
              <div className="row">
                <div className="col-lg-6 mb-5 product_detail">
                  {/* <img
                    // src={proDetail && proDetail?.images[0].url}
                    src={imageChange || (proDetail && proDetail?.images[0].url)} // Show the selected image or the first one
                    className="img-fluid"
                    alt=""
                  /> */}
                  {imageChange ? (
                    imageChange.type === "image" ? (
                      <img src={imageChange.url} className="img-fluid" alt="" />
                    ) : (
                      <video controls className="img-fluid">
                        <source src={imageChange.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )
                  ) : (
                    proDetail &&
                    (proDetail.images[0].type === "image" ? (
                      <img
                        src={proDetail.images[0].url}
                        className="img-fluid"
                        alt=""
                      />
                    ) : (
                      <video controls className="img-fluid">
                        <source
                          src={proDetail.images[0].url}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ))
                  )}
                  <div className="product_img_parent">
                    {/* {proDetail?.images?.map((img, index) => (
                      <img
                        key={index}
                        className="cursor-pointer"
                        src={img.url} // Display each image in the list
                        alt=""
                        onClick={() => setImageChange(img.url)} // Update the main image when a thumbnail is clicked
                        style={{
                          border:
                            img.url === imageChange
                              ? "2px solid #000" // Highlight selected image
                              : "1px solid #ccc",
                        }}
                      />
                    ))} */}
                    {proDetail?.images?.map((img, index) =>
                      img?.type === "image" ? (
                        <img
                          key={index}
                          className="cursor-pointer"
                          src={img.url}
                          alt={`image ${index + 1}`}
                          onClick={() => setImageChange(img)}
                          style={{
                            border:
                              imageChange?.url === img.url ||
                              (!imageChange && index === 0)
                                ? "2px solid #000"
                                : "1px solid #ccc",
                          }}
                        />
                      ) : (
                        <div className="video-container" key={index}>
                          <video
                            key={index}
                            // poster="/images/w3schools_green.jpg"
                            className="cursor-pointer"
                            onClick={() =>
                              setImageChange({ url: img.url, type: "video" })
                            }
                            style={{
                              border:
                                imageChange?.url === img.url
                                  ? "2px solid #000"
                                  : "1px solid #ccc",
                            }}
                          >
                            <source src={img.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          <FaPlay className="play-icon" />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="col-lg-6 product-details pl-md-5 ">
                  <h3 className="fw-bold"> {proDetail?.name}</h3>
                  <p className="price">
                    <span className="fw-bold"> {proDetail?.price}</span>
                  </p>
                  <p>{proDetail?.description}</p>
                  <div className="mt-4">
                    <div className="col-md-3 col-lg-3 col-xl-2 d-flex account">
                      <button
                        className="btn btn-link px-2 shadow-none"
                        onClick={handleDecrease}
                      >
                        <FaMinus style={{ color: "red" }} />
                      </button>

                      <input
                        id="form1"
                        min="1"
                        name="quantity"
                        value={quantity}
                        type="number"
                        className="form-control form-control-sm border-0"
                        readOnly
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                      />
                      <button
                        className="btn btn-link px-2 shadow-none"
                        onClick={handleIncrease}
                      >
                        <FaPlus style={{ color: "red" }} />
                      </button>
                    </div>
                  </div>
                   <div className="mt-3">
                  <span className="fw-bold"> Side Items</span>
                  <div className="item-grid">
                      {proDetail&&proDetail?.sideItemDetails.map(item => (
                        <div className="item-card">
                          <img src={item?.image} alt={item?.name} className="item-image" />
                          <div className="item-details">
                            <p className="item-name" >{item?.name}</p>
                            {/* <p >${item.price.toFixed(2)}</p> */}
                          </div>
                        </div>
                      ))}
                     
                    </div>
                 
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      class="btn btn-md btn-outline-danger shadow-none"
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  </div>
                 
                </div>
                
              </div>
            </>
          )}
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-center mb-3 pb-3">
            <div className="col-md-12 heading-section text-center ">
              <h2 className="mb-2 fw-bold">Related Products</h2>
            </div>
          </div>
        </div>
        <div className="container products mb-5">
          <div className="row row-cols-1 row-cols-md-4 g-5">
            {relproDetail.length > 0 ? (
              relproDetail.map((item) => (
                <div className="col" key={item._id}>
                  <div className="card">
                    {/* <div className="wishlist_add">
                      <FaRegHeart />
                    </div> */}
                    <Link
                      to={`/product-details/${item.categoryId}/${item._id}`}
                      className="text-decoration-none"
                    >
                      <img
                        src={item.images[0].url} // Use the image of the current related product
                        className="card-img-top"
                        alt={item.name}
                        style={{ height: "25vh" }}
                      />
                    </Link>
                    <div className="card-body">
                      <h5 className="card-title d-flex justify-content-center">
                        {item.name}
                      </h5>
                      {/* <p className="card-text">{item.title}</p> */}
                      {/* <div className="d-flex justify-content-between">
                        <span className="fw-bold">${item.price}</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No related products available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
