import React, { useState, useEffect } from "react";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdClose } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { IoMdLocate } from "react-icons/io";
import Modal from "react-modal";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setDefaultAddress } from "../../app/slice/DefaultAddressSlice";
import { Loader } from "../../Components/Loader/Loader";
import GooglePlacesInput from "../../Components/GooglePlaceInput/GooglePlacesInput";

const customStyles = {
  content: {
    top: "55%",
    left: "auto",
    right: "0",
    bottom: "auto",
    transform: "translateY(-50%)",
    width: "350px",
    height: "80vh",
    marginRight: "0",
    zIndex: "1100",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    border: "none",
  },
};
const inputClass = {
  backgroundColor: "white",
  outline: "none",
  boxShadow: "none",
  border: "none",
};

export const MyAddress = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [Address, setAddress] = useState([]);
  const [locations, setlocations] = useState(null);
  const [geoaddress, setgeoaddress] = useState("");

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [remark, setRemark] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [error, setError] = useState(null);

  const apiKey = `AIzaSyDwwL3sIm-b4s_qTweJ5u2nHSjmO7Y7RH8`;

  const handleSetDefaultAddress = (selectedAddress) => {
    // Update the default address in the state
    const updatedAddresses = Address?.map((addr) =>
      addr._id === selectedAddress._id
        ? { ...addr, isDefault: true }
        : { ...addr, isDefault: false }
    );
    setAddress(updatedAddresses);
    dispatch(setDefaultAddress(selectedAddress));
    toast.success("Default address set successfully.");
  };

  const GetAddress = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getAddress}`,
      method: "get",
    };
    console.log("sadsadsad", config);

    APIRequest(
      config,
      (res) => {
        setAddress(res?.data);
        console.log("sadasdaddress", res?.data);

        // setRemark(res?.remark)
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const removeAddress = (id) => {
    // console.log("Address Id",id);
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.removeAddress}/${id}`,
      method: "delete",
    };
    APIRequest(
      config,
      (res) => {
        setIsLoading(false);
        toast.success(res?.message);
        GetAddress();
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const addAddress = () => {
    if (!location.address && (!location.lat || !location.lng)) {
      toast.error(
        "Please enter an address or use 'Locate Me' to set your location."
      );
      return;
    }

    if (!location.name) {
      toast.error("House Type is required");
      return;
    }
    if (!location.city) {
      toast.error("city is required");
      return;
    }
    if (!location.state) {
      toast.error("state is required");
      return;
    }
    if (!location.zipCode) {
      toast.error("zipCode is required");
      return;
    }
    // if (!location.remark) {
    //   toast.error("remark is required");
    //   return;
    // }

    setIsLoading(true);
    let config = {
      url: `${ApiUrl.addAddress}`,
      method: "post",
      body: {
        address: location.address,
        name: location.name,
        zipCode: location.zipCode,
        remark: location.remark,
        city: location.city,
        state: location.state,
        name: location.name || "Unnamed Address",
        longitude: location.lng || undefined,
        latitude: location.lat || undefined,
      },
    };
    console.log("config", config);

    APIRequest(
      config,
      (res) => {
        toast.success(res?.message);
        setIsLoading(false);
        GetAddress();
        setLocation({
          lat: null,
          lng: null,
          address: "",
          name: "",
          zipCode: "",
          remark: "",
          city: "",
          state: "",
        }); // Reset the fields
        setmodalIsOpen(false);
        console.log("address", res);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  console.log("geoaddressgeoaddress", geoaddress);
  console.log("locations ", locations);

  const fetchAddress = async (latitude, longitude) => {
    setIsLoading(true);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    try {
      const response = await axios.get(url);
      if (response.data.status === "OK") {
        const result = response.data.results[0];
        console.log("result ssss", result);

        const address = result.formatted_address;
        const addressComponents = result.address_components;

        let city = "";
        let state = "";
        let zipCode = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          }
        });

        setLocation((prevLocation) => ({
          ...prevLocation,
          address,
          city,
          state,
          zipCode,
          lat: latitude,
          lng: longitude,
        }));
        setIsLoading(false);
      } else {
        setError("Geocoding Error", `Error: ${response.data.status}`);
        setIsLoading(false);
      }
    } catch (error) {
      setError(`Geocoding Request Failed: ${error.message}`);
      setIsLoading(false);
    }
  };

  const handleLocateMe = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setLocation({ lat: latitude, lng: longitude });
          setLocation((prevLocation) => ({
            ...prevLocation,
            lat: latitude,
            lng: longitude,
          }));
          console.log(location);
          fetchAddress(latitude, longitude);
          setLoading(false);
        },
        (error) => {
          setError(error.message);
          setLoading(false);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    GetAddress();
  }, []);
  useEffect(() => {
    fetchAddress(locations?.latitude, locations?.longitude);
  }, [locations]);

  // ========Add New Deliver Address Modal============
  const [modalIsOpen, setmodalIsOpen] = React.useState(false);

  function openModal() {
    setmodalIsOpen(true);
  }

  function closeModal() {
    setmodalIsOpen(false);
  }

  // ========Edit Deliver Address Modal============
  const [modalIsOpen1, setmodalIsOpen1] = React.useState(false);

  function openModal1() {
    setmodalIsOpen1(true);
  }

  function closeModal1() {
    setmodalIsOpen1(false);
  }
  return (
    <LayoutContainer>
      <section className="h-100">
        <div className="container h-100 py-5">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="fw-bold mb-0">My Address</h3>
                <button
                  type="button"
                  class="btn btn-danger shadow-none"
                  onClick={openModal}
                >
                  Add New Address
                </button>
                <Modal
                  isOpen={modalIsOpen}
                  // onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="d-flex justify-content-end">
                    <MdClose
                      className="fs-3"
                      onClick={() => setmodalIsOpen(false)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="fs-5 fw-bold">Add New Address</div>
                  <div className="d-flex justify-content-center">
                    <select
                      name="dropdown"
                      id=""
                      className="modal_name"
                      value={location.name || ""}
                      onChange={(e) =>
                        setLocation((prevLocation) => ({
                          ...prevLocation,
                          name: e.target.value,
                        }))
                      }
                    >
                      <option value="">Address Type</option>
                      <option value="Home">Home</option>
                      <option value="Work">Work</option>
                    </select>
                  </div>
                  {/* <div className="modal_name">
                    <input
                      type="text"
                      placeholder="House Type"
                      className="search"
                      value={location.name || ""}
                      onChange={(e) =>
                        setLocation((prevLocation) => ({
                          ...prevLocation,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div> */}
                  <div className="modal_name">
                    <GooglePlacesInput
                      inputClass={inputClass}
                      setValue={setlocations}
                      address={geoaddress}
                      setAddress={setgeoaddress}
                    />
                  </div>
                  <div className="modal_name">
                    <input
                      type="text"
                      placeholder="Enter Address"
                      className="search"
                      value={location.address}
                      onChange={(e) =>
                        setLocation({ ...location, address: e.target.value })
                      }
                    />
                  </div>
                  <div className="modal_name">
                    <input
                      type="text"
                      placeholder="Enter City"
                      className="search"
                      value={location.city}
                      onChange={(e) =>
                        setLocation({ ...location, city: e.target.value })
                      }
                    />
                  </div>
                  <div className="modal_name">
                    <input
                      type="text"
                      placeholder="Enter State"
                      className="search"
                      value={location.state}
                      onChange={(e) =>
                        setLocation({ ...location, state: e.target.value })
                      }
                    />
                  </div>

                  <div className="modal_name">
                    <input
                      type="text"
                      placeholder="Enter ZIP"
                      className="search"
                      value={location.zipCode}
                      onChange={(e) =>
                        setLocation({ ...location, zipCode: e.target.value })
                      }
                    />
                  </div>
                  <div className="modal_name">
                    <input
                      type="text"
                      placeholder="Remark"
                      className="search"
                      value={location.remark}
                      onChange={(e) =>
                        setLocation({ ...location, remark: e.target.value })
                      }
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "0.7rem",
                      marginLeft: "12px",
                    }}
                  >
                    (Optional)
                  </span>
                  <div className="modal_name">
                    <input
                      type="text"
                      placeholder="Entrance"
                      className="search"

                      // value={location.remark}
                      // onChange={(e) =>
                      //   setLocation({ ...location, remark: e.target.value })
                      // }
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "0.7rem",
                      marginLeft: "12px",
                    }}
                  >
                    (Optional)
                  </span>
                  <div className="modal_location">
                    <button onClick={handleLocateMe}>
                      <IoMdLocate style={{ fontSize: "20px" }} />
                      <span>Locate Me</span>
                    </button>
                  </div>

                  <div className="d-flex mt-3">
                    <button
                      className="btn btn-danger py-1 px-3 border-none outline-none rounded-pill me-2 shadow-none"
                      onClick={addAddress}
                      // disabled={!location.name || !location.address}
                    >
                      Save & Continue
                    </button>
                  </div>
                </Modal>
              </div>
              {isLoading ? (
                <Loader isLoading={isLoading} />
              ) : (
                <>
                  {Address.slice()
                    .reverse()
                    ?.map((item, index) => (
                      <div
                        className="card rounded-3 mb-4"
                        style={{ border: "2px dashed grey" }}
                      >
                        <div className="card-body">
                          <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-md-8 col-lg-10">
                              <p className="d-flex flex-column">
                                <span className="fw-bold">{item?.name} </span>
                                <span className="text-muted">
                                  {item?.address}
                                </span>
                                {item?.remark && (
                                  <div className="d-flex align-items-center mt-3">
                                    <h6 className="fw-bold">Remark:</h6>
                                    <h6 className="text-muted ms-2 fw-6">
                                      {item.remark}
                                    </h6>
                                  </div>
                                )}
                              </p>
                            </div>
                            <div className="col-md-1 col-lg-1 col-xl-1 text-end justify-content-center align-items-center">
                              <span className="text-danger">
                                <MdDelete
                                  className="fs-3"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => removeAddress(item?._id)}
                                />
                              </span>
                              {/* <span className="text-danger">
                              <FaEdit
                                className="fs-4"
                                onClick={openModal1}
                                style={{ cursor: "pointer" }}
                              />
                            </span> */}
                            </div>
                            <Modal
                              isOpen={modalIsOpen1}
                              // onAfterOpen={afterOpenModal}
                              onRequestClose={closeModal1}
                              style={customStyles}
                              contentLabel="Example Modal"
                            >
                              <div className="d-flex justify-content-end">
                                <MdClose
                                  className="fs-3"
                                  onClick={() => setmodalIsOpen1(false)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div className="fs-5 fw-bold">Edit Address</div>
                              {/* <p className="fs-6">
                        Which store would you like to Pickup/Dine-in order from
                      </p> */}
                              <div className="d-flex mt-4">
                                <div className="d-flex align-items-center flex-wrap me-3">
                                  <input
                                    type="radio"
                                    name="home"
                                    id=""
                                    className="me-1"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <label
                                    className="fw-bold"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Home
                                  </label>
                                </div>
                                <div className="d-flex align-items-center flex-wrap me-3">
                                  <input
                                    type="radio"
                                    name="home"
                                    id=""
                                    className="me-1"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <label
                                    className="fw-bold"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Office
                                  </label>
                                </div>
                                <div className="d-flex align-items-center flex-wrap me-3">
                                  <input
                                    type="radio"
                                    name="home"
                                    id=""
                                    className="me-1"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <label
                                    className="fw-bold"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Other
                                  </label>
                                </div>
                              </div>
                              <div className="modal_name">
                                <input
                                  type="text"
                                  placeholder="Name"
                                  className="search"
                                />
                              </div>
                              <div className="modal_name">
                                <input
                                  type="text"
                                  placeholder="Flat No./House No."
                                  className="search"
                                />
                              </div>
                              <div className="modal_name">
                                <input
                                  type="text"
                                  placeholder="Address"
                                  className="search"
                                />
                              </div>
                              <div className="modal_location">
                                <input
                                  type="search"
                                  placeholder="Enter Area/Locality"
                                  className="search"
                                />
                                <button>
                                  <IoMdLocate style={{ fontSize: "20px" }} />
                                  <span>Locate Me</span>
                                </button>
                              </div>
                              <div className="d-flex mt-3">
                                <button className="btn btn-danger py-1 px-3 border-none outline-none rounded-pill me-2">
                                  Save & Continue
                                </button>
                              </div>
                            </Modal>
                          </div>
                          <div className="d-flex ">
                            <input
                              type="radio"
                              name="address"
                              id="address"
                              className="me-2"
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() => handleSetDefaultAddress(item)}
                            />
                            <p className="fs-6">
                              {item.isDefault ? "Default" : "Set as Default"}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </LayoutContainer>
  );
};
