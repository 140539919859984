import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  defaultAddress: null,
};

const DefaultAddressSlice = createSlice({
  name: 'defaultAddress',
  initialState,
  reducers: {
    setDefaultAddress: (state, action) => {
      state.defaultAddress = action.payload;
    },
  },
});

export const { setDefaultAddress } = DefaultAddressSlice.actions;
export default DefaultAddressSlice.reducer;
