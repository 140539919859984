import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus, FaRegHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import { setCart } from "../../app/slice/CartSlice";
import { Loader } from "../Loader/Loader";

export const Products = () => {
  const token = localStorage.getItem("token");
  const StoreData = useSelector((state) => state.user.storeData);
  const cart = useSelector((state) => state.cart.cartData);
  const [activeButton, setActiveButton] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ProductList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterType, setFilterType] = useState("");
  const limit = 400;

  const SendRequest = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getProduct}`,
      method: "post",
      body: {
        longitude: StoreData?.location.coordinates[0],
        latitude: StoreData?.location.coordinates[1],
        page: currentPage,
        limit: limit,
      },
    };
    // Add filter parameters based on the active button
    switch (filterType) {
      case "Veg":
        config.body.isVeg = true;
        break;
      case "Non Veg":
        config.body.isVeg = false;
        break;
      case "Top Rated":
        config.body.isTopRated = true;
        break;
      // Add other cases as needed
      default:
        // No extra parameters for "All"
        break;
    }
    APIRequest(
      config,
      (res) => {
        setIsLoading(false);
        console.log("product api", res?.data);
        setProductList(res?.data);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setFilterType(buttonName);
  };

  const GetCart = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getCart}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        // console.log("i ama in cart details", res?.data);
        dispatch(setCart(res?.data[0]));
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          console.log("err", err?.message);
          // toast.error(err?.message)
        }
      }
    );
  };

  const AddToCart = (productId, storeId, quantity = 1) => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.addCart,
      method: "post",
      body: {
        productId: productId,
        storeId: storeId,
        quantity: quantity,
      },
    };
    APIRequest(
      config,
      (res) => {
        setIsLoading(false);
        // console.log("API Response:", res);
        GetCart();
        // if (res?.data) {
        //   console.log("Product Added", res.data[0]?.products);
        //   dispatch(setCart(res.data[0]?.products));
        //   toast.success("Product added to cart");
        // } else {
        //   toast.error('Failed to add product to cart');
        // }
      },
      (err) => {
        setIsLoading(false);
        // console.error("API Error:", err);
        toast.error(err?.message || "Failed to add product to cart");
      }
    );
  };

  const UpdateCart = (productId, storeId, quantity) => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.addCart}`,
      method: "post",
      body: {
        productId: productId,
        storeId: storeId,
        quantity: quantity,
      },
    };
    APIRequest(
      config,
      (res) => {
        setIsLoading(false);
        toast.success(res?.message);
        // dispatch(setCart(res?.created))
        GetCart();
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  useEffect(() => {
    if (StoreData?.location.coordinates[0]) {
      SendRequest();
      GetCart();
    }
  }, [StoreData, filterType]);

  // console.log("reducerdata", cart);
  const mergeData = () => {
    // Create a map of productId to quantity from cart data
    const cartMap = cart?.products?.reduce((acc, item) => {
      acc[item.productId._id] = item.quantity;
      return acc;
    }, {});

    // Add quantity to products if present in the cart
    return ProductList?.map((product) => ({
      ...product,
      itemQuantity: (cartMap && cartMap[product._id]) || 0,
    }));
  };
  const mergedData = mergeData();

  return (
    <>
      <div className="container mb-5 products">
        <div className="d-flex flex-wrap justify-content-start gap-2 mb-4">
          <div className="button_div">
            <button
              onClick={() => handleButtonClick("All")}
              type="button"
              className={`btn ${
                activeButton === "All" ? "btn-danger" : "btn-outline-danger"
              } fw-bold cursor-pointer rounded-pill px-4 py-1 shadow-none`}
            >
              All
            </button>
          </div>
          <div className="button_div">
            <button
              onClick={() => handleButtonClick("Top Rated")}
              type="button"
              className={`btn ${
                activeButton === "Top Rated"
                  ? "btn-danger"
                  : "btn-outline-danger"
              } fw-bold cursor-pointer rounded-pill px-4 py-1 shadow-none`}
            >
              Top Rated
            </button>
          </div>
          {/* <div className="button_div">
            <button
              onClick={() => handleButtonClick("Veg")}
              type="button"
              className={`btn ${
                activeButton === "Veg" ? "btn-danger" : "btn-outline-danger"
              } fw-bold cursor-pointer rounded-pill px-4 py-1 shadow-none`}
            >
              Veg
            </button>
          </div> */}
          {/* <div className="button_div">
            <button
              onClick={() => handleButtonClick("Non Veg")}
              type="button"
              className={`btn ${activeButton === "Non Veg" ? "btn-danger" : "btn-outline-danger"
                } fw-bold cursor-pointer rounded-pill px-4 py-1 shadow-none`}
            >
              Non Veg
            </button>
          </div> */}
        </div>

        <div className="fs-4 fw-bold text-grey mb-2">Recommended</div>
        <div className="row row-cols-1 row-cols-md-4 g-5">
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <>
              {mergedData?.map((item) => {
                return (
                  <div className="col" key={item?._id}>
                    <div className="card">
                      <Link
                        to={`/product-details/${item.categoryId}/${item._id}`}
                        className="text-decoration-none"
                      >
                        <img
                          src={item?.images[0]?.url} // Correctly access the URL property of the first image
                          className="card-img-top"
                          alt="..."
                          style={{ height: "25vh" }}
                        />
                      </Link>
                      <div className="card-body">
                        <h5 className="card-title">{item?.category}</h5>
                        <p className="card-text">{item?.title}</p>
                        <div className="d-flex justify-content-between">
                          <span className="fw-bold">${item?.price}</span>
                          {item?.itemQuantity < 1 ? (
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-danger shadow-none"
                              onClick={() =>
                                token
                                  ? AddToCart(item._id, StoreData._id)
                                  : navigate("/sign-in")
                              }
                            >
                              Add to Cart
                            </button>
                          ) : (
                            <div className="clo-lg-4 col-md-6 d-flex account">
                              <button
                                className="btn btn-link px-2 shadow-none"
                                onClick={() =>
                                  item?.itemQuantity > 0 &&
                                  UpdateCart(item?._id, cart?.storeId, -1)
                                }
                              >
                                <FaMinus style={{ color: "red" }} />
                              </button>
                              <input
                                id="form1"
                                min="0"
                                name="quantity"
                                value={item?.itemQuantity}
                                type="number"
                                className="form-control form-control-sm w-25 border-0"
                                readOnly
                                style={{
                                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                }}
                              />

                              <button
                                className="btn btn-link px-2 shadow-none"
                                onClick={() =>
                                  UpdateCart(item?._id, cart?.storeId, 1)
                                }
                              >
                                <FaPlus style={{ color: "red" }} />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};
