import React from "react";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <>
      <footer class="footer mt-auto py-3 bg-light">
        <div className="container">
          <div className="row">
            <div className="mouse">
              <Link to="#" className="mouse-icon">
                <div className="mouse-wheel">
                  <span className="ion-ios-arrow-up"></span>
                </div>
              </Link>
            </div>
          </div>
          <div className="row mb-5 mt-3">
            <div className="col-md">
              <div className=" mb-4">
                <h2 className="fw-bold text-dark">Penguin Express</h2>
              </div>
            </div>
            <div className="col-md">
              <div className=" mb-4 ml-md-5">
                <h4 className="text-dark">Menu</h4>
                <ul className="list-unstyled text-dark">
                  <li>
                    <Link
                      to="/"
                      className="py-2 d-block text-dark text-decoration-none  "
                    >
                      Shop
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/"
                      className="py-2 d-block text-dark text-decoration-none  "
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/"
                      className="py-2 d-block text-dark text-decoration-none"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftco-footer-widget mb-4">
                <h4 className="ftco-heading-2 text-dark">Help</h4>
                <div className="d-flex">
                  <ul className="list-unstyled mr-l-5 pr-l-3 mr-4 text-dark">
                    <li>
                      <Link
                        to="/"
                        className="py-2 d-block text-dark text-decoration-none"
                      >
                        Returns &amp; Exchange
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/"
                        className="py-2 d-block text-dark text-decoration-none"
                      >
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className=" mb-4">
                <h4 className=" text-dark">Have a Questions?</h4>
                <div className="block-23 mb-3">
                  <ul>
                    <li>
                      <span className="icon icon-map-marker"></span>
                      <span className="text text-dark">
                        203 Fake St. Mountain View, San Francisco, California,
                        USA
                      </span>
                    </li>
                    <li>
                      <Link to="/" className="text-decoration-none">
                        <span className="icon icon-phone text-dark"></span>
                        <span className="text text-dark">+2 392 3929 210</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" className="text-decoration-none">
                        <span className="icon icon-envelope text-dark"></span>
                        <span className="text text-dark">
                          info@yourdomain.com
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
