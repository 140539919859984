import React, { useEffect, useState } from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { APIRequest, ApiUrl } from "../../utils/api";
import { Link, useLocation } from "react-router-dom";

export const PaymentFailed = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const Payment_Failed_Fun = () => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.transaction_cancel,
      method: "post",
      body: {
        token: token,
      },
    };
    APIRequest(
      config,
      (res) => {
        console.log("res =============", res);
        if (!res?.error) {
          setIsLoading(false);
        }
      },
      (err) => {
        console.log("err", err);
        if (err?.error) {
          setIsLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    Payment_Failed_Fun();
  }, []);

  return (
    <>
      <LayoutContainer>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="message-box _success _failed">
                <i className="fa fa-times-circle" aria-hidden="true"></i>
                <h2> Your payment failed </h2>
                <p> Try again later </p>
                <Link to="/cart">
                  <button
                    className="btn btn-danger btn-lg btn-block fs-6 text-transform-none shadow-none mt-4"
                    type="submit"
                  >
                    Go Back
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};
