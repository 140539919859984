import React, { useEffect, useState } from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { APIRequest, APIRequestWithFile, ApiUrl } from "../../utils/api";
import { setLogin } from "../../app/slice/LoginSlice";
import { setUserData } from "../../app/slice/UserSlice";
import { MdEdit } from "react-icons/md";

export const Account = () => {
  // const dispatch = useDispatch()
  // const navigation = useNavigate()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getUserProfile = () => {
    setIsLoading(true);
    let config = {
      url: ApiUrl?.userDetails,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        if (!res?.error) {
          // console.log("=======Response=========",res);
          setIsLoading(false);
          setImage(res?.user?.image);
          setFirstName(res?.user?.firstName);
          setLastName(res?.user?.lastName);
          setEmail(res?.user?.email);
          setContact(res?.user?.contact);
          setAddress(res?.user?.fullAddress);
          // navigation("/app/login");
        }
      },
      (err) => {
        setIsLoading(false);
        if (err?.error) {
          // console.log("=====Error=======",err?.message);
        }
      }
    );
  };
  const updateUserProfile = () => {
    setIsLoading(true);
    let formdata = new FormData();
    formdata.append("name", firstName);
    formdata.append("name", lastName);
    formdata.append("contact", contact);
    formdata.append("address", address);
    formdata.append("image", image);
    // formdata.append('countryCode', '+91');

    let config = {
      url: ApiUrl?.updateProfile,
      method: "post",
      body: formdata,
    };
    APIRequestWithFile(
      config,
      (res) => {
        // console.log("user updated", res);
        toast.success(res?.message);
        setIsLoading(false);
        getUserProfile();
        // navigation("/app/login");
      },
      (err) => {
        setIsLoading(false);
        // console.log("erroere",err?.message);
        if (err?.error) {
          toast.error(err?.message);
        }
      }
    );
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <LayoutContainer>
      <section className="vh-150">
        <div className="container py-5 h-100 account">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 ">
                  <div className="text-center position-relative">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      id="fileInput"
                    />
                    <label htmlFor="fileInput">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          className="rounded-circle img-fluid"
                          style={{
                            width: "10rem",
                            height: "10rem",
                            cursor: "pointer",
                          }}
                          alt="Profile"
                        />
                      ) : (
                        <img
                          src={image}
                          className="rounded-circle img-fluid"
                          style={{
                            width: "10rem",
                            height: "10rem",
                            cursor: "pointer",
                          }}
                          alt="Profile"
                        />
                      )}
                    </label>
                    <div
                      className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
                      style={{
                        bottom: "0",
                        right: "38%",
                        backgroundColor: "red",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <MdEdit style={{ color: "white" }} />
                    </div>
                  </div>
                  <h3 className="mb-3 text-center">Account Details</h3>
                  <div className="d-flex flex-column mb-2">
                    <label className="mb-0 fs-7">First Name</label>
                    <input
                      type="name"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      id="typeName"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter First Name"
                    />
                  </div>
                  <div className="d-flex flex-column mb-2">
                    <label className="mb-0 fs-7">Last Name</label>
                    <input
                      type="name"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      id="typeName"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Last Name"
                    />
                  </div>
                  <div className="d-flex flex-column mb-2">
                    <label className="mb-0 fs-7">Email</label>
                    <input
                      type="email"
                      // onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="typeEmail"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Email"
                      disabled
                    />
                  </div>
                  <div className="d-flex flex-column mb-2">
                    <label className="mb-0 fs-7">Mobile</label>
                    <input
                      type="number"
                      onChange={(e) => setContact(e.target.value)}
                      value={contact}
                      id="typeMobile"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Mobile"
                    />
                  </div>
                  {/* <div className="d-flex flex-column mb-3">
                    <label className="mb-0 fs-7">Address</label>
                    <input
                      type="text"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      id="typeAddress"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Address"
                      disabled
                    />
                  </div> */}
                  <button
                    onClick={updateUserProfile}
                    className="btn btn-danger btn-lg w-100 btn-block fs-6 text-transform-none shadow-none mt-2"
                    type="submit"
                  >
                    {isLoading ? <span>Processing...</span> : <span>Save</span>}
                  </button>
                  <div className="text-center mt-3">
                    <p>
                      Change Password?
                      <Link
                        to="/change-password"
                        className="text-decoration-none fs-6"
                      >
                        ChangePassword
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LayoutContainer>
  );
};
