import React from 'react';
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer';
import { HomeHero } from "../../Components/HomeHero/HomeHero";
import { Products } from '../../Components/Products/Products';
import { ProductType } from '../ProductType/ProductType';

export const Home = () => {
  return (
    <>
    {/* Test */}
      <LayoutContainer>
        <HomeHero />
        <ProductType />
        <Products />
      </LayoutContainer>
    </>
  );
}