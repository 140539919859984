import React, { useEffect, useState } from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { APIRequest, ApiUrl } from '../../utils/api';
import {setLogin } from '../../app/slice/LoginSlice';
import { setUserData } from '../../app/slice/UserSlice';
import { useDispatch } from 'react-redux';

export const SignIn = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function resetFun() {
      setEmail('')
      setPassword('')
  }
  const loginAuth = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.login}`,
      method: "post",
      body: {
        data: email,
        password: password,
        longitude: location?.lng,
        latitude: location?.lat,
      },
    };
    console.log("successsuccess", config);
    APIRequest(
      config,
      (res) => {
        console.log(res, "---res");
        localStorage.setItem("token", res?.token)
        
        dispatch(setLogin(true))
        dispatch(setUserData(res?.token))
        toast.success(res?.message)
        setIsLoading(false)
        resetFun()  
        navigation("/")
      },
      (err) => {
        console.log(err.message, "---err");
        setIsLoading(false)
        if (err?.message) {
          toast.error(err?.message)
        }
      }
    );
  }
  // }

  const submitHandler = (e) => {
      e.preventDefault()
      // console.log("login value",email,password,location);
      if (email === '') {
          alert('Email field is required')
      } else if (password === '') {
          alert('Password field is required')
      } else {
          loginAuth()
      }

  }

  const getLocation = () => {
      if (navigator.geolocation) {
          setLoading(true);
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  const { latitude, longitude } = position.coords;
                  setLocation({ lat: latitude, lng: longitude });
                  setLoading(false);
              },
              (error) => {
                  setError(error.message);
                  setLoading(false);
              }
          );
      } else {
          setError('Geolocation is not supported by this browser.');
      }
  };

  useEffect(() => {
      getLocation();
  }, []);

  return (
    <LayoutContainer>
      <section className="vh-150">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 ">
                  <h3 className="mb-3 text-center">Sign In</h3>
                  {/* <div className="d-flex flex-column mb-3">
                    <label className="mb-1">Name</label>
                    <input
                      type="name"
                      id="typeName"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Full Name"
                    />
                  </div> */}
                  <div className="d-flex flex-column mb-3">
                    <label className="mb-1">Email or Mobile</label>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="typeEmail"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Email or Mobile"
                    />
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="mb-1">Password</label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      id="typePassword"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Password"
                    />
                  </div>
                  {/* Checkbox */}
                  <div class="row mb-4 ">
                    <div className="col-md-6 d-flex justify-content-start">
                      {/* <!-- Checkbox --> */}
                      <div className="form-check mb-3 mb-md-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="loginCheck"
                        />
                        <label className="form-check-label" for="loginCheck">
                          Remember me
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                      {/* <!-- Simple link --> */}
                      <Link
                        to="/forgot-password"
                        className="text-decoration-none"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                  <button
                    onClick={submitHandler}
                    className="btn btn-danger btn-lg btn-block fs-6 text-transform-none w-100 shadow-none"
                    type="submit"
                  >
                    {isLoading ? (
                      <span>Processing...</span>
                    ) : (
                      <span>Sign In</span>
                    )}
                  </button>
                  <div className="text-center mt-3">
                    <p>
                      Don't have an account?
                      <Link to="/sign-up" className="text-decoration-none">
                        SignUp
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LayoutContainer>
  );
};
