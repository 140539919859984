import React from 'react'
import { LayoutContainer } from '../LayoutContainer/LayoutContainer'

export const Loader = ({isLoading}) => {
  return (
    isLoading && (
      <section
        className="d-flex justify-content-center align-items-center w-100 h-100"
      >
        <div
          class="spinner-border"
          role="status"
          style={{ width: "4rem", height: "4rem" }}
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </section>
    )
  );
}
