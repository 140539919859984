import React, { Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import { Page404 } from "../Components/Page404/Page404";
import { Home } from "../Pages/Home/Home";
import { Cart } from "../Pages/Cart/Cart";
import { MyOrder } from "../Pages/MyOrder/MyOrder";
import { SignIn } from "../Pages/SignIn/SignIn";
import { SignUp } from "../Pages/SignUp/SignUp";
import { ForgotPassword } from "../Pages/ForgotPassword/ForgotPassword";
import { Account } from "../Pages/Account/Account";
import { Feedback } from "../Pages/Feedback/Feedback";
import { MyAddress } from "../Pages/MyAddress/MyAddress";
import { SingleProduct } from "../Pages/SingleProduct/SingleProduct";
import { Wishlist } from "../Pages/Wishlist/Wishlist";
import { ProductTypePage } from "../Pages/ProductTypePage/ProductTypePage";
import { useSelector } from "react-redux";
import { Auth } from "./Auth";
import { PaymentSuccessfull } from "../Pages/PaymentSuccessfull/PaymentSuccessfull";
import { PaymentFailed } from "../Pages/PaymentFailed/PaymentFailed";
import { ChangePassword } from "../Components/ChangePassword/ChangePassword";
import { SearchProductPage } from "../Pages/SearchProductPage/SearchProductPage";
import { OrderSuccessfull } from "../Pages/OrderSuccessfull/OrderSuccessfull";
import { Wallet } from "../Pages/Wallet/Wallet";
import { WalletPaymentSuccessfull } from "../Pages/WalletPaymentSuccessfull/WalletPaymentSuccessfull";
import { WalletPaymentFailed } from "../Pages/WalletPaymentFailed/WalletPaymentFailed";
import Reward from "../Pages/Reward/Reward";

const Layout = () => {
  const LogIn = useSelector((state) => state.login.isLogin);
  const UserData = useSelector((state) => state.user.userData);

  // console.log("LogIn",LogIn);
  // console.log("UserData", UserData);

  return (
    <div className="main_section">
      <BrowserRouter>
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />

          <Route path="/" element={<Home />} />
          <Route path="/product-type/:typeId" element={<ProductTypePage />} />
          <Route
            path="/product-details/:categoryId/:productId"
            element={<SingleProduct />}
          />
          <Route path="/product-search" element={<SearchProductPage />} />

          {/* <Route path="/" element={<Auth />}> */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/my-orders" element={<MyOrder />} />

          <Route path="/account" element={<Account />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/my-address" element={<MyAddress />} />

          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/payment-successfull" element={<PaymentSuccessfull />} />
          <Route path="/payment-failed" element={<PaymentFailed />} />
          <Route
            path="/wallet-payment-successfull"
            element={<WalletPaymentSuccessfull />}
          />
          <Route
            path="/wallet-payment-failed"
            element={<WalletPaymentFailed />}
          />
          <Route path="/order-successfull" element={<OrderSuccessfull />} />
          {/* </Route> */}

          {/* Redirecting unknown url to 404 page */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Layout;
