import React from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer'
import { FeedbackDetails } from '../../Components/FeedbackDetails/FeedbackDetails'

export const Feedback = () => {
  return (
    <>
      <LayoutContainer>
        <FeedbackDetails />
      </LayoutContainer>
    </>
  );
}
