import React from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer'
import { SingleProductDetail } from '../../Components/SingleProductDetail/SingleProductDetail'

export const SingleProduct = () => {
  return (
    <>
      <LayoutContainer>
        <SingleProductDetail />
      </LayoutContainer>
    </>
  );
}
