import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useNavigate } from 'react-router-dom';
import { APIRequest, ApiUrl } from '../../utils/api';
import { toast } from 'react-toastify';

export const HomeHero = () => {
  const navigation = useNavigate()
  const [Data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const SendRequest = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getBanner}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        // console.log(res);        
        setData(res?.data)
      },
      (err) => {
        setIsLoading(false)
        if (err?.message) {
          toast.error(err?.message)
        }
      }
    );
  }
  useEffect(() => {
    SendRequest()
  }, [])

  return (
    <>
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        style={{
          zIndex: "-1",
        }}
      >
        <div className="carousel-inner" style={{ height: "300px" }}>
          {Data?.map((item, index) => (
            <div key={item?._id} className="carousel-item active">
              <img
                src={item?.image}
                className="d-block w-100"
                alt="banner-img"
                style={{
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
          {/* <div className="carousel-item">
            <img
              src="/assets/images/bg_2.jpg"
              className="d-block w-100"
              alt="..."
              style={{
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div> */}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};
