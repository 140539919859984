import React, { useEffect, useState } from "react";
import { LayoutContainer } from "../../Components/LayoutContainer/LayoutContainer";
import { FaSearch, FaMinus, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export const SearchProductPage = () => {
  const token = localStorage.getItem("token");
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const StoreData = useSelector((state) => state.user.storeData);
  const CartData = useSelector((state) => state.cart.cartData);
  // const navigate = useNavigate();

  useEffect(() => {
    SearchProduct();
  }, [text]);

  const SearchProduct = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.Search}`,
      method: "post",
      body: {
        data: text,
        storeId: StoreData._id,
      },
    };

    APIRequest(
      config,
      (res) => {
        setIsLoading(false);
        console.log("Search Product", res?.data);
        setSearchResults(res?.data);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          //   toast.error(err?.message);
        }
      }
    );
  };

  return (
    <LayoutContainer>
      <section className="container mt-5 pt-3 mb-5">
        <div className="subscribe-form d-flex justify-content-center w-100">
          <div className="form-group form-outline d-flex col-md-12 col-lg-8 border border-black">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <input
              type="button"
              value="Search"
              className="submit px-3"
              onClick={SearchProduct}
            />
          </div>
        </div>
      </section>
      <div className="container mb-5 products">
        <div className="row row-cols-1 row-cols-md-4 g-5">
          {searchResults?.map((item) => (
            <div className="col" key={item?._id}>
              <div className="card">
                <Link
                  to={`/product-details/${item.categoryId}/${item._id}`}
                  className="text-decoration-none"
                >
                  <img
                    src={item?.images[0].url}
                    className="card-img-top"
                    alt="..."
                    style={{ height: "25vh" }}
                  />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">{item?.category}</h5>
                  <p className="card-text">{item?.title}</p>
                  <div className="d-flex justify-content-between">
                    <span className="fw-bold">${item?.price}</span>
                    {/* <Link to="/product-details">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger shadow-none"
                        >
                          Add
                        </button>
                      </Link> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <Loader isLoading={isLoading} /> */}
      </div>
    </LayoutContainer>
  );
};
