import React, { useEffect, useState } from 'react'
import { LayoutContainer } from '../LayoutContainer/LayoutContainer'
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { APIRequest, ApiUrl } from '../../utils/api';

export const ChangePassword = () => {
  const navigation = useNavigate()
  const [oldPassword, setoldPassword] = useState("")
  const [newPassword, setnewPassword] = useState("")
  const [confirmPassword, setconfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  
      const handlerUpdate = () => {
      
        if (newPassword !== confirmPassword) {
          toast.error("New password and confirm password do not match");
          return;
        }
      
        setIsLoading(true)
        let config = {
            url: ApiUrl?.changePassword,
            method: "post",
            body: {
                "oldPassword": oldPassword,
                "password": newPassword,
            }
        }
        APIRequest(
            config,
            res => {
                if (!res?.error) {
                  resetPasswordFields();
                  console.log("sdasdas", res);
                    toast.success(res?.message)
                    setIsLoading(false)
                    navigation("/sign-in");
                }
            },
            err => {
                setIsLoading(false)
                if (err?.error) {
                  console.log("erroere",err?.message);
                    toast.error(err?.message)
                }
            }
        )
      }
      const resetPasswordFields = () => {
        setoldPassword("");
        setnewPassword("");
        setconfirmPassword("");
      };
      
      
    // useEffect(() => {
    //     handlerUpdate();
    // }, []);

  return (
    <LayoutContainer>
        <section className="vh-150">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 ">
                  <h3 className="mb-3 text-center">Change Password</h3>
                  <div className="d-flex flex-column mb-3">
                    <label className="mb-1">Old Password</label>
                    <input
                      type="password"
                      onChange={(e) => setoldPassword(e.target.value)}
                      value={oldPassword}
                      id="oldpassword"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter Old Password"
                    />
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="mb-1">New Password</label>
                    <input
                      type="password"
                      onChange={(e) => setnewPassword(e.target.value)}
                      value={newPassword}
                      id="newpassword"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Enter New Password"
                    />
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="mb-1">Confirm New Password</label>
                    <input
                      type="password"
                      onChange={(e) => setconfirmPassword(e.target.value)}
                      value={confirmPassword}
                      id="confirmpassword"
                      className="form-control p-2 border border-1 shadow-none"
                      placeholder="Confirm New Password"
                    />
                  </div>
                  <div class="row mb-4 ">
                    <div className="col-md-6 d-flex justify-content-start">
                      {/* <!-- Checkbox --> */}
                      <div className="form-check mb-3 mb-md-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="loginCheck"
                        />
                        <label className="form-check-label" for="loginCheck">
                          Remember me
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={handlerUpdate}
                    className="btn btn-danger btn-lg btn-block fs-6 text-transform-none w-100 shadow-none"
                    type="submit"
                  >
                    {isLoading ? (
                      <span>Processing...</span>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LayoutContainer>
  )
}
